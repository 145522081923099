<template>
  <div class="bloglists">
    <b-container fluid
    >
      <b-row cols="1" cols-sm="1" cols-md="3" cols-lg="3" cols-xl="4" cols-xxl="5">
        <!--  -->
        <b-col v-for="(blog, index) in blogsPerPage" :key="blog.id">
          <b-card
            :title="blog.title"
            :img-src="getImgUrl(blog.imagepath)"
            :class="{ selected: isSelected(index) }"
            :img-alt="blog.title"
            img-top
            tag="article"
            style="max-width: 20rem; border-radius: 3%"
            class="mb-2"
            @mouseover="hoverCard(index)"
            @mouseout="hoverCard(-1)"
            @click.prevent="prev"
            v-dragged="onDragged"
          >
          <div>
              <span class="mobCat" @click.stop.prevent="openCategory(blog.category)">
                <a><small>{{ blog.category }}</small></a>
              </span>
            </div>
            <b-card-text>
              <small class="text-monospace font-weight-normal description"
                >{{ blog.description}}..</small
              >
            </b-card-text>
            <b-button
              sm="2"
              md="2"
              lg="4"
              @click="openBlog(blog)"
              class="submit pb-2"
              size="sm"
              >Learn more</b-button
            >
          </b-card>
        </b-col>
        <!--  -->
      </b-row>
    </b-container>
    <Pagin
      v-bind:blogLength="getLength()"
      v-bind:perPage="perPage"
      v-bind:activePage="activePage"
      @pageChange="changePage"
    />
  </div>
</template>

<script>
import { HTTP } from '../../HTTP'
import Pagin from '../helpers/Pagin'
import moment from 'moment'
export default {
  components: {
    Pagin
  },
  name: 'SomeBlogsMobile',
  props: {
    blogId: String,
    blogLength: Number
  },
  data () {
    return {
      perPage: 1,
      currentPage: 1,
      blogs: [],
      selectedCard: -1,
      showPag: true,
      activePage: 1,
      slider: null,
      isMoving: false
    }
  },
  computed: {
    rows () {
      return this.blogs.length
    },
    blogsPerPage () {
      return this.blogs.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    },
    formattedBlogs () {
      return this.blogs.reduce((b, n, i) => {
        if (i % 4 === 0) b.push([])
        b[b.length - 1].push(n)
        return b
      }, [])
    }
  },
  watch: {
    isMoving () {
      document.querySelector('body').style.overflow = this.isMoving ? 'hidden' : null
    }
  },
  methods: {
    prev (e) {
      e.preventDefault()
    },
    totalPages () {
      return Math.ceil(this.blogs.length / this.perPage)
    },
    dateThreshold (d, nrOfMonth) {
      const month = d.getMonth()
      d.setMonth(d.getMonth() - nrOfMonth)
      while (month === d.getMonth()) {
        d.setDate(d.getDate() - nrOfMonth)
      }
      return d
    },
    changePage (currentPage) {
      this.currentPage = currentPage
      this.activePage = currentPage
      clearInterval(this.slider)
    },
    getLength () {
      return this.blogs.length
    },
    openBlog (blog) {
      /*
      localStorage.setItem('id', blog.id)
      this.$router.push({
        name: 'Blog',
        params: {
          id: blog.title.replace(/\s+/g, '-').toLowerCase()
        }
      })
      */
      window.location.href = 'https://blog.haniftadjik.com/Blog?id=' + blog.id
    },
    openCategory (category) {
      window.location.href = "https://blog.haniftadjik.com/GetSimilarBlogs/en/" + category
      /*
      localStorage.setItem('category', category)
      this.$router.push({
        name: 'Category'
      }).catch(() => {})
      */
    },
    getImgUrl (imageName) {
      console.log(imageName)
      var images = require.context('../../assets/figure/', false, /\.png$/)
      return images('./' + imageName + '.png')
    },
    hoverCard (selectedIndex) {
      this.selectedCard = selectedIndex
    },
    isSelected (blogIndex) {
      return this.selectedCard === blogIndex
    },
    onDragged ({ el, deltaX, /* offsetX, clientX, */deltaY, first, last }) {
      if (first) {
        this.isMoving = true
        this.isDragging = true
        return
      }
      var l = +window.getComputedStyle(el).left.slice(0, -2) || 0
      if (Math.abs(deltaY) < 5) {
        el.style.left = l + deltaX + 'px'
      }
      if (last) {
        this.isMoving = false
        if (l > 35) {
          if (this.currentPage === this.totalPages()) {
            this.changePage(1)
            this.isDragging = false
            return
          } else {
            this.changePage(this.currentPage + 1)
            this.isDragging = false
            return
          }
        }
        if (l < -35) { // todo: left does not work
          if (this.currentPage === 1 || this.currentPage < 1) {
            this.changePage(this.totalPages())
            this.isDragging = false
            return
          }
          if (this.currentPage > 1) {
            this.changePage(this.currentPage - 1)
            this.isDragging = false
            return
          }
        }
        el.style.left = '0px'
        this.isDragging = false
      }
    }
  },
  async created () {
    await HTTP.get('GetBlogs/en').then((response) => {
      if (response.data.data !== null) {
        this.blogs = response.data.data
      }
    })
    await this.blogs.forEach((blog) => {
      if (blog.title.length > 30) {
        blog.title = blog.title.slice(0, 30)
        blog.title += '..'
      }
      blog.description = blog.description.slice(0, 50)
    })
    var d = this.dateThreshold(new Date(), 6)
    var dateThresh = moment(new Date(d.toDateString())).format('YYYY-MM-DD')
    this.blogs.filter((blog) => {
      return dateThresh > moment(new Date(blog.pubdate)).format('YYYY-MM-DD')
    }
    )
    if (this.blogs.length > 12) {
      this.blogs = this.blogs.slice(0, 11)
    }
  },
  mounted () {
    if (this.slider) {
      clearInterval(this.slider)
    } else {
      this.slider = setInterval(() => {
        if (this.currentPage < Math.ceil(this.blogs.length / this.perPage)) {
          this.currentPage++
          this.activePage++
        } else {
          this.currentPage = 1
          this.activePage = 1
        }
      }, 5000)
    }
  }
}
</script>

<style lang="css" scoped>
.bloglists {
  max-width: 95%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}
.card {
  padding: 2.5%;
  text-align: left;
  margin: 2%;
  overflow: hidden;
  overflow-x: hidden;
  touch-action: pan-y;
  -ms-touch-action: pan-y;
  box-shadow: 0px 1px 2px 0px #376996;
 /*  transition: height 1.5s, box-shadow 1.5s; */
}
/* .card:hover {
  box-shadow: 2px 6px 10px 0px #376996;
} */
.card img {
  transition: all 1.5s ease-in-out;
  border-radius: 5%;
}
/* .card:hover img {
  transform: scale(1.02);
} */
.submit {
  display: inline-block;
  padding: 6px 12px;
  background-color: #829cbc;
  border: 1px solid #829cbc;
  border-radius: 4px;
  transition: all 0.8s ease-in;
  position: relative;
  overflow: hidden;
  font-size: 0.85em;
  color: #ffff;
  z-index: 1;
}
.submit:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}
.submit:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #376996;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}
.submit:hover {
  color: #ffffff;
  border: 1px solid #376996;
}
.submit:hover:before {
  top: -35%;
  background-color: #376996;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}
.submit:hover:after {
  top: -45%;
  background-color: #376996;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.mobCat {
  display: block;
  height: 25px;
  line-height: 25px;
  width: 40px;
  font-size: 0.7rem;
  color: #ffffff;
  text-align: center;
  background-color: #a10702;
  border-radius: 1%;
  border: 0.3px solid #a10702;
  display: inline-block;
  box-shadow: 0.5px 0.5px 1px #f44708, -0.5px -0.5px 1px #ffffff;
  margin: 0.75%;
}
.mobCat:hover {
  background-color: #a10702;
}
</style>
