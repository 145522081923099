<template>
  <div class="bloglists">
    <hr class="hr1" />
    <b-container fluid>
      <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="4" cols-xl="5" cols-xxl="6">
        <!--  -->
        <b-col v-for="(blog, index) in blogsPerPage" :key="blog.id">
          <b-card :title="blog.title" :img-src="getImgUrl(blog.imagepath)" :class="{ selected: isSelected(index) }"
            :img-alt="blog.title" img-top tag="article" style="max-width: 20rem; border-radius: 3%" class="mb-2"
            @mouseover="hoverCard(index)" @mouseout="hoverCard(-1)" @click="openBlog(blog)">
            <b-card-text>
              <small class="text-monospace font-weight-normal description">{{ blog.description }}..</small>
            </b-card-text>
            <div>
              <span class="category" @click.stop.prevent="openCategory(blog.category)">
                <a><small>{{ blog.category }}</small></a>
              </span>
            </div>
          </b-card>
        </b-col>
        <!--  -->
      </b-row>
    </b-container>
    <div v-if="blogs.length">
      <Pagin :activePage="activePage" v-bind:blogLength="blogs.length" v-bind:perPage="perPage"
        @pageChange="changePage" />
    </div>
    <hr class="hr1" />
  </div>
</template>

<script>
import { HTTP } from '../../HTTP'
import Pagin from './Pagin'
import moment from 'moment'

export default {
  components: {
    Pagin
  },
  name: 'SomeBlogs',
  props: {
    blogId: String
  },
  data() {
    return {
      perPage: 4,
      currentPage: 1,
      blogs: [],
      selectedCard: -1,
      activePage: 1
      /* slider: null, */
    }
  },
  computed: {
    rows() {
      return this.blogs.length
    },
    blogsPerPage() {
      return this.blogs.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    },
    formattedBlogs() {
      return this.blogs.reduce((b, n, i) => {
        if (i % 4 === 0) b.push([])
        b[b.length - 1].push(n)
        return b
      }, [])
    }
  },
  methods: {
    dateThreshold(d, nrOfMonth) {
      const month = d.getMonth()
      d.setMonth(d.getMonth() - nrOfMonth)
      while (month === d.getMonth()) {
        d.setDate(d.getDate() - nrOfMonth)
      }
      return d
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.activePage = currentPage
      clearInterval(this.slider)
    },
    openBlog(blog) {
      /* console.log("is cookie accepte: ", localStorage.getItem("cookie:accepted"));
      localStorage.setItem('id', blog.id)
      this.$router.push({
        name: 'Blog',
        params: {
          id: blog.title.replace(/\s+/g, '-').toLowerCase()
        }
      })
      */
      // window.location.href = 'https://blog.haniftadjik.com'
      window.location.href = 'https://blog.haniftadjik.com/Blog?id=' + blog.id
    },
    openCategory(category) {
      /* localStorage.setItem('category', category)
      this.$router.push({
        name: 'Category'
      }).catch(() => {})
      */
      window.location.href = "https://blog.haniftadjik.com/GetSimilarBlogs/en/" + category
    },
    getImgUrl(imageName) {
      var images = require.context('../../assets/figure/', false, /\.png$/)
      return images('./' + imageName + '.png')
    },
    hoverCard(selectedIndex) {
      this.selectedCard = selectedIndex
    },
    isSelected(blogIndex) {
      return this.selectedCard === blogIndex
    }
  },
  async created() {
    await HTTP.get('GetBlogs/en').then((response) => {
      if (response.data.data !== null) {
        this.blogs = response.data.data
      }
    })
    await this.blogs.forEach((blog) => {
      if (blog.title.length > 30) {
        blog.title = blog.title.slice(0, 30)
        blog.title += '..'
      }
      blog.description = blog.description.slice(0, 70)
    })
    var d = this.dateThreshold(new Date(), 3)
    var dateThresh = moment(new Date(d.toDateString())).format('YYYY-MM-DD')
    this.blogs.filter((blog) => {
      return dateThresh > moment(new Date(blog.pubdate)).format('YYYY-MM-DD')
    }
    )
    if (this.blogs.length > 12) {
      this.blogs = this.blogs.slice(0, 11)
    }
  },
  mounted() {
    if (this.slider) {
      clearInterval(this.slider)
    } else {
      this.slider = setInterval(() => {
        if (this.currentPage < Math.ceil(this.blogs.length / this.perPage)) {
          this.currentPage++
          this.activePage++
        } else {
          this.currentPage = 1
          this.activePage = 1
        }
      }, 15000)
    }
  },
  beforeUnmount() {
    clearInterval(this.changer)
  }
}
</script>

<style lang="css" scoped>
.bloglists {
  max-width: 95%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1200px) {
  .bloglists .b-card {
    margin: 10px;
    padding: 10px;
  }
}

.hr1 {
  margin: auto;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  height: 3px;
  background-image: linear-gradient(to right, #6290c8, #829cbc, #6290c8);
}

.card {
  padding: 2%;
  text-align: left;
  margin: 2%;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px #376996;
  transition: height 0.5s, box-shadow 0.5s;
}

.card:hover {
  box-shadow: 2px 6px 10px 0px #376996;
}

.card img {
  transition: all 0.5s ease-in-out;
  border-radius: 5%;
}

.card:hover img {
  transform: scale(1.02);
}
</style>
