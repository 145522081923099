<template>
  <div>
    <div>
      <span
        v-for="pageNumber in totalPages"
        :key="pageNumber"
        class="dot"
        v-bind:class="{ active: pageNumber ===  activePage}"
        @click="setPage(pageNumber)"
        ><a></a>
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'pagin',
  props: {
    blogLength: Number,
    perPage: Number,
    activePage: Number
  },
  data () {
    return {
      currentPage: 1
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.blogLength / this.perPage)
    }
  },
  methods: {
    setPage (pageNumber) {
      this.currentPage = pageNumber
      this.$emit('pageChange', this.currentPage)
    }
  },
  created () {
  }
}
</script>

<style scoped>
.dot {
  height: 0.50rem;
  width: 0.50rem;
  border: 0.0001 solid #376996;
  background-color: #6290c8;
  border-radius: 50%;
  display: inline-block;
  margin: 0.35%;
}

.dot:hover {
  background-color: #376996;
}
.active {
  background-color: #1d3461;
}
</style>
