<template>
  <ErrorHandler>
  <div id="app">
    <Navbar />
    <router-view :key="$route.fullPath"></router-view>
    <!-- Cookie -->
    <cookie-law id="laws">
        <div slot-scope="props" id="accept-ignore">
          <p>
            We use
            <a class="cook"><router-link to="/privacy" >cookies</router-link></a> to
            give you the best online experience. <a style="text-decoration: none;color:#376996;"
            ><router-link to="/privacy" >Read more...</router-link></a
          >
          </p>
          <button class="skew submit" @click="props.accept" size="md">
            <span>Got it</span>
          </button>
          <button class="skew ignore" @click="props.close" size="md">
            <span>Ignore</span>
          </button>
          <!-- <a @click="showPolicy" class="cook"> -->
          <!-- <button class="skew ignore" @click="props.close" size="md"><span>Ignore</span></button> -->
        </div>
      </cookie-law>
    <Footer class="footer"/>
  </div>
  </ErrorHandler>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import ErrorHandler from './components/ErrorHandler.vue'

export default {
  name: 'App',
  props: {
  },
  data () {
    return {
    }
  },
  components: {
    Navbar,
    Footer,
    ErrorHandler
  },
  methods: {
    showPolicy () {
      this.$router.push({
        name: 'Privacy'
      }).catch(() => {})
    }
  }
}
</script>

<style>
#app {
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1d3461;
  margin-top: 60px;
}

</style>
<style scoped>
#laws {
  max-height: 180px;
  background-color: #ffffff;
  border-top: 1px solid #829cbc;
}
#accept-ignore {
  width: 100%;
  text-align: center;
}

#accept-ignore > * {
  display: inline-block;
  margin: 1.5%;
}
.cook {
  color: #376996;
  text-decoration: none;
}

.ignore {
  display: inline-block;
  padding: 12px 24px;
  background-color: #829cbc;
  border: 1px solid #829cbc;
  border-radius: 4px;
  transition: all 0.8s ease-in;
  position: relative;
  overflow: hidden;
  font-size: 0.85em;
  color: #ffff;
  z-index: 1;
}

.ignore:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.ignore:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #a10702;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.ignore:hover {
  color: #ffffff;
  border: 1px solid #a10702;
}

.ignore:hover:before {
  top: -35%;
  background-color: #829cbc;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.ignore:hover:after {
  top: -45%;
  background-color: #a10702;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}
</style>
