import axios from 'axios'

const HTTP = axios.create(
  {
    baseURL: process.env.VUE_APP_API_URL,
    /* baseURL: 'http://b888-178-232-3-182.ngrok.io', */
    headers: {
    /* "Content-type": "application/json",  */
    /* "Access-Control-Allow-Origin":"*" */
    /* Authorization: '' */
    }
  })

// TODO: shoudl make a new env file and add the key in there
const CRYPTO = axios.create({
  baseURL: 'https://api.polygon.io/v2/aggs',
  headers: {
    // "Content-type": "application/json",
    // "Access-Control-Allow-Origin":"*"
    Authorization: 'Bearer ' + process.env.VUE_APP_CRYPTO_TOKEN // remove the toke from here
  }
})
export { HTTP, CRYPTO }
