import Vue from 'vue'
import VueRouter from 'vue-router'
import SomeBlogsMobile from './components/mobile/SomeBlogsMobile.vue'
import SomeBlogs from './components/helpers/SomeBlogs.vue'

Vue.use(VueRouter)
function lazyLoad (view) {
  return () => import('./components/' + view)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: lazyLoad('Home'),
    props: true,
    children: [
      { path: '/', component: SomeBlogs, props: true },
      { path: '/', component: SomeBlogsMobile, props: true }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: lazyLoad('About')
  },
  {
    path: '/mint',
    name: 'Mint',
    component: lazyLoad('Mint')
  },
  {
    path: '/techversechat',
    name: 'TechVerseChat',
    component: lazyLoad('TechVerseChat')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: lazyLoad('Contact')
  },
  {
    path: '/search',
    name: 'Search',
    component: lazyLoad('Search')
  },
  {
    path: '/no',
    name: 'Norsk',
    component: lazyLoad('Norsk')
  },
  {
    path: '/fa',
    name: 'Farsi',
    component: lazyLoad('Farsi')
  },
  {
    path: '/notfound',
    name: 'NotFound',
    component: lazyLoad('NotFound')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: lazyLoad('Privacy')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: lazyLoad('Terms')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes: routes,
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
