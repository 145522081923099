import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin, BadgePlugin, SpinnerPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueCryptojs from 'vue-cryptojs'
import IconCrypto from 'vue-cryptocurrency-icons'
import CookieLaw from 'vue-cookie-law'
import VDragged from 'v-dragged'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faLinkedin, faFacebook, faTelegram, faReddit } from '@fortawesome/free-brands-svg-icons'

import router from './router'
import { IsMobile } from './components/helpers/utils.js'
import './assets/css/main.css'
// import './assets/css/root.scss';

var VueCookie = require('vue-cookie')
Vue.use(VueCookie)
Vue.use(VueCryptojs)
Vue.use(IconCrypto)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BadgePlugin)
Vue.use(SpinnerPlugin)
Vue.use(VDragged)
Vue.component('CookieLaw', CookieLaw)
Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faTwitter, faFacebook, faLinkedin, faTelegram, faReddit)

Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0)
Vue.prototype.$skDecEnc = process.env.VUE_APP_CRYPTO_EN_DEC_SECRET_KEY
// Tell Vue to use the plugin

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
  router,
  data: {
    isMobile: IsMobile()
  }
}).$mount('#app')
