<template>
  <div>
    <b-navbar type="light" toggleable="lg" fixed="top">
      <b-navbar-brand :to="{ name: 'Home' }">
        <img src="../assets/figure/log_X2.png" alt="Blockchain NFT Metaverse DAO Consensus DID Web 3.0" width="40"
          height="40" class="d-inline-block align-text-center">
        <span class="span-name">Hanif Tadjik</span>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <template>
          <b-icon icon="chevron-bar-down" class="icontoggler"></b-icon>
        </template>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav center>
        <b-navbar-nav class="navLists">
          <b-nav-item :to="{ name: 'Home' }"
            :class="{ active: $route.name === 'Home' || $route.path === '/' }"><a>Home</a></b-nav-item>
          <b-nav-item href="https://blog.haniftadjik.com/"><a>Blog</a></b-nav-item>
          <b-nav-item :to="{ name: 'About' }" :class="{ active: $route.name === 'About' }"><a>About</a></b-nav-item>
          <b-nav-item :to="{ name: 'Contact' }"
            :class="{ active: $route.name === 'Contact' }"><a>Contact</a></b-nav-item>
        </b-navbar-nav>

        <div class="no-filters">
          <b-navbar-nav>
            <!-- <b-nav-form> -->
            <input size="md" class="mr-sm-2 search" no-caret placeholder="Search.." maxlength="250" v-model="search"
              pattern="[a-zA-Z0-9-åÅæÆøØ-آابپتثجچحخدذرزژسشصضطظعغفقکگلمنوهی ]+" @keyup.enter="searchResults" />
            <!-- </b-nav-form> -->
            <b-nav-item-dropdown class="lang-dropdown" no-caret size="sm" right>
              <template #button-content>
                <b-icon id="iconi" icon="globe2" aria-hidden="true"></b-icon>
              </template>
              <a class="dropdown-item"><router-link :to="{ name: 'Home' }">English</router-link></a>
              <a class="dropdown-item"><router-link :to="{ name: 'Norsk' }">Norsk</router-link></a>
              <a class="dropdown-item"><router-link :to="{ name: 'Farsi' }">Farsi</router-link></a>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </div>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: ''
    }
  },
  methods: {
    searchResults() {
      localStorage.setItem('search', this.search)
      this.search = ''
      if (this.$route.name === 'Search') {
        window.location.reload()
      } else {
        this.$router.push({ name: 'Search', query: { search: this.$route.query.search } })
      }
    }
  }
}
</script>

<style scoped>
div {
  top: 0;
  left: 0;
  height: fit-content;

}

.navbar {
  /* overflow: hidden; */
  max-width: 100%;
  background: #ffff;
  box-shadow: 1px 1px 7px 0px #829cbc;
  min-height: 60px !important;
  padding: 0px;
  z-index: 4;
  transform: scale(1.02);
  touch-action: none;
}

.navbar-nav {
  justify-content: right !important;
}

.navbar-toggler {
  margin-right: 3%;
  vertical-align: middle;
}

.navbar-brand {
  margin-left: 4%;
}

.navbar-brand img {
  margin-right: 2%;
}

.navbar-brand .span-name {
  color: #1d3461;
  padding-left: 4%;
  padding-right: 4%;
  font-weight: bold;
  font-style: oblique;
  font-size: 1.2rem;
}

.navbar-nav .nav-item {
  margin-right: 2%;
}

.navbar-nav .nav-item a {
  text-decoration: none;
  color: #376996;
  font-size: 1.15rem;
}

.navbar-nav .nav-item a:hover {
  text-decoration: none;
  color: #1d3461;
}

#logo {
  margin-right: 1%;
  height: 40;
  width: 40;
}

.navbar-nav .lang-dropdown {
  text-align: center;
  width: 100px;
  touch-action: none;
}

.lang-dropdown:focus {
  outline: red;
}

.navbar-nav .nav-item .dropdown-item {
  font-size: 0.9rem;
  color: #1d3461;
  width: 5rem;
  margin-right: 0px;
}

#iconi {
  transition: all 0.3s ease-in-out;
  color: #376996;
}

#iconi:hover {
  transform: scale(1.25);
  color: #1d3461;
}

.icontoggler {
  color: #1d3461;
}

.active a {
  color: #1d3461 !important;
  font-weight: bold;
}

.navLists {
  width: 50%;
}

div.no-filters {
  width: 50%;
  vertical-align: middle;
  justify-content: center !important;
  margin-right: 10%;
}

.search {
  border: 0.2px solid #829cbc;
  outline: 0;
  padding-inline: 10px;
  margin-left: 5%;
}

@media screen and (max-width: 800px) {
  .search {
    margin-left: 10%;
  }
}

.search:focus {
  border: 0.2px solid #376996;
  box-shadow: 0.5px 2px 3px 1px #829cbc;
}

.search:hover {
  border: 0.2px solid #376996;
  box-shadow: 0.5px 2px 3px 1px #829cbc;
}
</style>
