import { render, staticRenderFns } from "./SomeBlogsMobile.vue?vue&type=template&id=e0bbcfc0&scoped=true"
import script from "./SomeBlogsMobile.vue?vue&type=script&lang=js"
export * from "./SomeBlogsMobile.vue?vue&type=script&lang=js"
import style0 from "./SomeBlogsMobile.vue?vue&type=style&index=0&id=e0bbcfc0&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0bbcfc0",
  null
  
)

export default component.exports